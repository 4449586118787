<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="
      supplierReturnStatusRequest.value === 'loading-getSupplierReturns'
    "
    loading-text="Đang tải dữ liệu"
    :items="supplierReturns"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.value`]="{ item }">
      {{ item.value | formatCurrency }}
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="item.status === 1 ? 'green' : 'red accent-2'"
        small
        outlined
      >
        {{ item.status === 1 ? "Đã trả" : "Đã hủy" }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Mã phiếu trả",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Thời gian tạo",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Nhà cung cấp",
          align: "start",
          sortable: false,
          value: "supplier_code"
        },
        {
          text: "Chi nhánh",
          align: "start",
          sortable: false,
          value: "branch_name"
        },
        {
          text: "Nhân viên",
          align: "start",
          sortable: false,
          value: "created_user_name"
        },
        {
          text: "Giá trị trả",
          align: "start",
          sortable: false,
          value: "value"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ]
    };
  },
  computed: {
    supplierReturns() {
      return this.$store.getters["SUPPLIER_RETURN/supplierReturns"];
    },
    supplierReturnStatusRequest() {
      return this.$store.getters["SUPPLIER_RETURN/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getIndexOfSaleReceipt(val) {
      const arr = this.supplierReturns.map(item => item.id);

      return arr.indexOf(val);
    },

    async viewDetail(item) {
      await this.$store.dispatch(
        "SUPPLIER_RETURN/getSupplierReturnById",
        item.id
      );

      this.$modal.show({
        name: "modal-supplier-return"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
