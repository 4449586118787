<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :branches-filter="branchesFilter"
        :date-filter="dateFilter"
        :employees-filter="employeesFilter"
        :status-filter="statusFilter"
        @updateBranchesFilter="updateBranchesFilter($event)"
        @updateDateFilter="updateDateFilter($event)"
        @updateEmployeesFilter="updateEmployeesFilter($event)"
        @updateStatusFilter="updateStatusFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        :search-type="searchType"
        :search-options="searchOptions"
        @exportReportFile="exportReportFile($event)"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { exportExcelFile } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },

  data() {
    return {
      searchType: 0,
      searchOptions: [
        { type: "search", label: "Theo tên hàng" },
        { type: "search", label: "Theo Serial" },
        { type: "search", label: "Theo MPN" },
        { type: "search", label: "Theo SKU" },
        { type: "search", label: "Theo mã phiếu" }
      ],
      branchesFilter: [],
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      employeesFilter: [],
      itemPerPage: 50,
      searchKey: null,
      statusFilter: null,
      typeFilter: null
    };
  },

  watch: {
    "$route.query"(val) {
      this.getSupplierReturns(val);
    }
  },

  async created() {
    const route = this.$route;
    await this.getSupplierReturns(route.query);
  },

  methods: {
    async exportReportFile() {
      await exportExcelFile(this, {
        storeName: "SUPPLIER_RETURN",
        payload: {
          branchIds: this.branchesFilter,
          status: this.statusFilter,
          employeeIds: this.employeesFilter,
          fromDate: this.dateFilter.value ? this.dateFilter.value[0] : null,
          toDate: this.dateFilter.value ? this.dateFilter.value[1] : null
        },
        fileName: "bao-cao-tra-hang-ncc",
        isCheckExportTime: true
      });
    },

    async pushRouter() {
      await this.$router.push({
        name: "transaction_supplier-return",
        query: {
          branch_id:
            this.branchesFilter && this.branchesFilter.length > 0
              ? this.branchesFilter
              : undefined,
          created_by_id:
            this.employeesFilter && this.employeesFilter.length > 0
              ? this.employeesFilter
              : undefined,
          status:
            this.statusFilter && this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getSupplierReturns(query) {
      if (query.page) {
        // Set filtered branches
        this.branchesFilter =
          typeof query.branch_id === "string"
            ? [parseInt(query.branch_id)]
            : typeof query.branch_id === "object"
            ? query.branch_id.map(item => parseInt(item))
            : [];
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set filtered branches
        this.employeesFilter =
          typeof query.created_by_id === "string"
            ? [parseInt(query.created_by_id)]
            : typeof query.created_by_id === "object"
            ? query.created_by_id.map(item => parseInt(item))
            : [];
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;
        // Set filtered status
        this.statusFilter = query.status ? parseInt(query.status) : "all";

        // Get products
        await this.$store.dispatch("SUPPLIER_RETURN/getSupplierReturns", {
          filter: {
            branch_id: this.branchesFilter,
            created_by_id: this.employeesFilter,
            status:
              this.statusFilter && this.statusFilter !== "all"
                ? [this.statusFilter]
                : null,
            fromDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            toDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateBranchesFilter(val) {
      this.branchesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateDateFilter(val) {
      this.dateFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateEmployeesFilter(val) {
      this.employeesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
